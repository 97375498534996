import { css } from '@emotion/react';

const TableSkeletonStyles = ({ theme }) => css`
  .skeleton-cell {
    padding: 0px 28px;
    display: flex;
    align-items: center;
    height: 54px;
    .MuiSkeleton-root {
      width: 100%;
      height: 16px;
    }
  }
`;

export default TableSkeletonStyles;
