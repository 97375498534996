import { useMemo } from 'react';
import { Theme } from 'connex-cds';
import { createTheme } from '@mui/material/styles';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';

import getDesignConfig from '../modes/config';

const ThemeProvider = ({ children }) => {
  const { themeName } = Theme.useThemeContext();

  const theme = useMemo(() => createTheme(getDesignConfig(themeName)), [themeName]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
