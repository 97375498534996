import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { Box, Skeleton, Stack } from '@mui/material';
import styled from '@emotion/styled';
import TableSkeletonStyles from './style';

const Styled = styled(Stack)`
  ${TableSkeletonStyles}
`;

const TableSkeleton = ({ rowHeight }) => {
  const apiRef = useGridApiContext();
  const dimensions = apiRef.current?.getRootDimensions();
  const viewportHeight = dimensions?.viewportInnerSize.height ?? 0;
  const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight) - 1;
  const children = useMemo(() => {
    const array = [];

    for (let i = 0; i < skeletonRowsCount; i += 1) {
      array.push(
        <Box className="skeleton-cell" key={i}>
          <Skeleton animation="wave" variant="rounded" />
        </Box>
      );
    }
    return array;
  }, [skeletonRowsCount]);

  return (
    <Styled data-testid="skeleton-table" className="skeleton-wrapper">
      {children}
    </Styled>
  );
};

TableSkeleton.propTypes = {
  rowHeight: PropTypes.number,
};

export { TableSkeleton };
